<template>
  <form
    ref="root"
    class="inline-flex justify-center items-center min-w-[140px] min-h-[46px] relative z-0"
    @submit.prevent="form.handleSubmit"
  >
    <div class="input">
      <JBorder
        class="w-full h-full rounded-[5px_6px_6px_5px]"
        :background-style="{
          background: 'transparent',
        }"
      >
        <div class="input__inner">
          <div class="max-w-full p-[16px] overflow-hidden">
            <input
              type="email"
              placeholder="your business email"
              class="w-full outline-none font-[570] bg-transparent text-base text-[#070707] placeholder:text-[#C5C5C5] placeholder:text-sm"
              name="email"
              required
              aria-required="true"
              aria-invalid="false"
              @input="form.handleInput"
            />
          </div>
        </div>
      </JBorder>
    </div>
    <button
      class="origin-right"
      :disabled="form.processing.value"
      type="submit"
    >
      Subscribe
    </button>

    <p
      v-if="form.error.value"
      class="absolute left-1/2 w-[320px] top-full mt-2 font-[570] text-xs text-[#FF0080] text-center -translate-x-1/2"
    >
      Something went wrong, please try again.
    </p>
    <p
      v-else-if="form.success.value"
      class="absolute left-1/2 w-[320px] top-full mt-2 font-[570] text-xs text-[#7928CA] text-center -translate-x-1/2"
    >
      Thank you for subscribing.
    </p>
  </form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import JBorder from "./JBorder.vue";
import { useContactForm } from "../utilities/contactForm";

const root = ref(null as unknown as HTMLFormElement);
const form = useContactForm({
  form: root,
  formId: "de24df5e0efc4b5",
});
</script>

<style scoped>
.input {
  @apply absolute inset-y-0 left-1/2 rounded-[5px_6px_6px_5px] -translate-x-1/2;
  @apply w-[300px] right-[-8px] overflow-hidden;
}

.input__inner {
  @apply relative flex items-center w-full h-full rounded-[5px_6px_6px_5px] bg-white border border-transparent bg-clip-padding z-10;
  @apply text-[#707070] text-[12px] tracking-[-1%] whitespace-nowrap;
}

button {
  @apply relative z-10 flex items-center justify-center w-[140px] h-[46px] bg-white border border-[#C5C5C5] active:top-[1px] font-[570] text-[#3726F4] tracking-[-0.01em] hover:border-[#3726F4] disabled:opacity-50;
  @apply text-[12px] h-[32px] w-[90px] translate-x-[98px] rounded-[3px] transition-colors duration-200 ease-in-out;
}
</style>
